<div class="md-stepper-horizontal">

  <div class="md-step" id="stepInfoPersonal">
      <div class="md-step-circle">1</div>
      <div class="md-step-title">
        <span class="stepCorrecto">Información Personal</span>
      </div>
      <div class="md-step-bar-right" id="lineaInfoPerDer"></div>
  </div>
  
  <div class="md-step" id="stepInfoComercial">
      <div class="md-step-circle">2</div>
      <div class="md-step-title">
        <span class="stepCorrecto">Actividad comercial</span>
      </div>
      <div class="md-step-bar-left" id="lineaInfComIzq"></div>
      <div class="md-step-bar-right" id="lineaInfComDer"></div>
  </div>

  <div class="md-step" id="stepInfoDocs">
      <div class="md-step-circle">3</div>
      <div class="md-step-title">
        <span class="stepCorrecto">Documentos requeridos</span>
      </div>
      <div class="md-step-bar-left" id="lineaDocIzq"></div>
      <div class="md-step-bar-right" id="lineaDocDer"></div>
  </div>

  <div class="md-step" id="stepAutorizacion">
      <div class="md-step-circle">4</div>
      <div class="md-step-title">
        <span class="stepCorrecto">Autorizaciones y términos</span>
      </div>
      <div class="md-step-bar-left" id="lineaAutoIzq"></div>
  </div>
  
</div>
